<template>
  <div>
    Payment Result
  </div>
</template>

<script>
export default {
  name: "PaymentResult"
};
</script>

<style scoped>
</style>
